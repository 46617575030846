<template>
	<tbody>
	<tr class="bg-white rounded p-1 border-b-2 shadow-sm h-16">
		<td class="border-right-none w-20"><span>{{ parseDate(notification.created_at) }}</span></td>
		<td class="border-right-none w-70"><span>{{ notification.text }}</span>
		</td>
		<td class="border-left-none w-5">
			<span class=" badge h-8 text-white cursor-pointer" v-if="notification.is_read === false"
				  v-on:click.prevent="readNotification">
				<span class="mark-read">{{ $t('markAsRead') }}</span>
			</span>
		</td>
	</tr>
	</tbody>
</template>

<script>
import moment from "moment";

export default {
	name: "notificationCard",
	props: {
		notification: {
			required: true,
			type: Object
		}
	},
	methods: {
		parseDate: function (val) {
			if (val !== null) {
				return moment(val).format('YYYY-MM-DD')
			} else {
				return ''
			}
		},
		readNotification() {
			this.marking = true
			this.$store.dispatch('portal/readNotification', {id: this.notification.id})
				.then(() => {
					this.marking = false
					this.$services.helpers.notification(this.$t('notificationNowMarkAsRead'), 'success', this)
					window.Bus.$emit('reload-notification')
				}).catch(err => {
				this.marking = false
				window.Bus.$emit('output-error', err)
			})
		}
	}
}
</script>

<style scoped>
.badge {
	border: 1px solid #E8B105;
	color: #E8B105 !important;
}

.mark-read {
	position: relative;
	top: 6px;
}

.w-70 {
	width: 70%;
}

.border-right-none {
	border-right: 1px solid transparent;
}

.border-left-none {
	border-left: 1px solid transparent;
}
</style>
