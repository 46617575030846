<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12">
					<div class="row no-gutters space-y-4 lg:space-y-0">
						<div class="col-12">
							<div>
								<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer">
									<span data-cy='article-heading'
										  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
											$t('integrations')
										}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full">
						<div class="grid grid-cols-1 lg:grid-cols-4 gap-3 animate-pulse" v-if="loading">
							<div class="rounded bg-gray-200" v-for="i in 8" :key="i">
								<div
									class="h-[100px] w-full flex flex-col rounded-lg mb-2 space-y-1">
									<div class="p-[29px]">
										<div class="w-full mt-1 mr-10 rounded bg-gray-200 cursor-pointer">

										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="grid grid-cols-1 lg:grid-cols-4 gap-3" v-else>
							<integration-card :company_id="companyInfo.id" :integration="integration" :company="companyInfo"
											  v-for="(integration,key) in integrationList"
											  :key="key"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
import integrationCard from "./components/integrations/integrationCard";

export default {
	name: "Integrations",
	components: {
		integrationCard
	},
	data: function () {
		return {
			loading: true,
			integrationList: [],
			companyInfo: {}
		}
	},
	methods: {
		getIntegrationsList: function () {
			this.loading = true
			this.$store.dispatch('portal/getIntegrationProvider').then(res => {
				this.integrationList = res.data
				this.loading = false
			}).catch(err => {
				this.loading = false
			})

		},
		getCompanyInformation: function () {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.companyInfo = res.data
					this.getIntegrationsList()
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		}
	},
	mounted() {
		this.getCompanyInformation()

	}
}
</script>

<style scoped>

</style>
