<template>
	<div class="bg-white h-full rounded shadow">
		<div class="h-[204px] w-fulll">
			<video ref="videoPlayer" class="video-js"></video>
		</div>
		<div class="p-[15px]">
			<div class="py-[5px]">
				<p class="text-xl font-semibold">{{ video[locale_title] }}</p>
			</div>
			<div>
				<p>{{ video[locale_description] }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from 'video.js';
import {mapGetters} from "vuex"

export default {
	name: "videoCard",
	props: {
		video: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			player: null,
			loading: false
		}
	},
	mounted() {
		let self = this
		this.player = videojs(this.$refs.videoPlayer, this.video.options, () => {
			self.loading = true
		});
		let player = document.getElementsByClassName('video-js')
		if (player) {
			player.forEach(el => {
				if (el.addEventListener) {
					el.addEventListener('contextmenu', function (e) {
						e.preventDefault();
					}, false);
				} else {
					el.attachEvent('oncontextmenu', function () {
						window.event.returnValue = false;
					});
				}
			})
		}

	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
	computed: {
		...mapGetters({
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		}),
		locale_title: function () {
			return this.GET_LOCALE === 'se' ? 'swe_title' : 'eng_title'
		},
		locale_description: function () {
			return this.GET_LOCALE === 'se' ? 'swe_description' : 'eng_description'
		},
	}
}
</script>

<style scoped>

</style>
