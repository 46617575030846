<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="row flex flex-col md:flex-row justify-between items-center align-items-center mb-3">
				<div data-cy='setting-header'>
				</div>
				<div
					class="flex flex-col md:flex-row w-11/12 md:w-auto justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xs">
					<span data-cy='tab1' @click.prevent="showTab(1)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-l-md"
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('readNotification')
						}}</span>
					<span data-cy='tab4' @click.prevent="showTab(2)"
						  class="v-company-setting-5 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-r-md whitespace-nowrap"
						  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('unreadNotification')
						}}</span>
				</div>
			</div>

			<div class="row">
				<div data-cy='table1' class="w-full mt-3">
					<div class="table-responsive rounded-lg bg-white border" v-loading="loading"
						 v-if="ALL_NOTIFICATION.data.length > 0">
						<table style="width: 100%;" id="exampledestroy"
							   class="table table-hover table-bordered rounded table-responsive ">
							<notification-card v-for="(notification,key) in ALL_NOTIFICATION.data" :key="key"
											   :notification="notification"/>
						</table>
					</div>
					<div v-else class="rounded text-center" v-loading="loading">
						<table style="width: 100%;">
							<tbody>
							<tr class="bg-white rounded p-1 border-b-2 shadow-sm h-16">
								<td class="border-right-none w-full" colspan="1"><span>{{
										$t('notificationEmpty')
									}}</span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="pagination-block" v-if="ALL_NOTIFICATION.data">
						<paginate
							:page-count="ALL_NOTIFICATION.total_pages"
							:click-handler="changePageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination space-x-2'">
						</paginate>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {mapGetters} from "vuex"
import notificationCard from "./notificationCard";
import Paginate from 'vuejs-paginate'

export default {
	name: "Notifications",
	components: {
		notificationCard,
		paginate: Paginate,
	},
	data: function () {
		return {
			tab: 2,
			loading: true
		}
	},
	methods: {
		changePageNumber: function (num) {
			let URL = `?limit=20&page=${num}`
			let tab = this.tab === 1 ? 'read' : 'unread'
			if (tab === 'unread') {
				URL += `&status=unread`
			} else {
				URL += `&status=read`
			}
			this.loading = true
			this.$store.dispatch('portal/getAllNotification', {data: URL}).then(() => {
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		showTab: function (tab) {
			let URL = `?limit=20`
			if (tab === 2) {
				URL += `&status=unread`
			} else {
				URL += `&status=read`
			}
			this.tab = parseInt(tab)
			this.loading = true
			this.$store.dispatch('portal/getAllNotification', {data: URL}).then(() => {
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		getCompany: function () {
			this.$store.dispatch('portal/getCompanyDetails').then(() => {
				// this.getAllNotification()
			})
		},
		getAllNotification: function () {
			this.loading = true
			this.$store.dispatch('portal/getAllNotification', {data: `?status=read`}).then(() => {
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		}
	},
	computed: {
		...mapGetters({
			ALL_NOTIFICATION: 'portal/GET_ALL_NOTIFICATION',
			COMPANY_SETTINGS: 'portal/GET_COMPANY_SETTING'
		})
	},
	mounted() {
		this.getCompany()
		window.Bus.$on('reload-notification', () => {
			this.showTab(2)
		})
		this.showTab(2)
		// this.getAllNotification()
	}
}
</script>
<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 15px !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.7rem 0.55rem !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.settings-page-contain {
	background-color: #fff;
	padding: 20px 30px 30px;
	border-radius: 4px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

label.label-w-input {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

label.label-w-input input {
	margin-right: 10px;
}

.el-color-picker {
	.el-color-picker__trigger {
		height: 31px !important;
		width: 31px !important;
	}
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}


</style>
