<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12">
					<div class="row no-gutters space-y-4 lg:space-y-0">
						<div class="col-12">
							<div>
								<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer">
									<span data-cy='article-heading'
										  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
											$t('videoGuides')
										}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full">
						<video-listing :mode="`buyer`"/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import videoListing from "../../customComponent/videos/videoListing";

export default {
	name: "videoComponent",
	components: {
		videoListing
	}
}
</script>

<style scoped>

</style>
