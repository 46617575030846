<template>
	<div class="h-full">
		<div
			class="w-full flex flex-col bg-white rounded-lg mb-2 space-y-1 bg-white">
			<div v-on:click="onConnect" class="p-[29px]">
				<div class="w-full mt-1 mr-10 rounded bg-gray-200 cursor-pointer" v-loading="processing">
					<img :src="integration.image" :alt="integration.name" class="rounded  mx-auto h-full"/>
				</div>

			</div>
		</div>
		<div class="w-full mt-[5px]" v-if="company">
			<div v-if="company.integration !== null"
				 class="flex justify-between align-items-center border rounded-md text-xs md:text-xl px-2 bg-green-500">

				<span data-cy='add-btn'
					  class="text-xs w-full h-10 text-white flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md">
                        {{ $t('connected') }}
                    </span>
			</div>

			<div v-else
				 class="flex justify-between align-items-center border rounded-md text-xs md:text-xl px-2 bg-theme-blue">
				<span data-cy='fortnox-connect' :class="{'cursor-pointer': processing === false}"
					  v-on:click="onConnect"
					  class="text-xs w-full h-10 text-white flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md">
                        {{ $t('connect') }} <svg v-if="processing === true" class="animate-spin ml-1 h-4 w-4 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                    </span>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: "integrationCard",
	props: {
		integration: {
			type: Object,
			required: true
		},
		company_id: {
			type: String,
			required: true
		},
		company: {
			type: Object
		}
	},
	data: function () {
		return {
			processing: false
		}
	},
	methods: {
		onConnect: function () {
			let payload = {'company_id': this.company_id, 'integration_id': this.integration.id}
			this.processing = true
			if (this.integration.provider_type === 'fortnox') {
				this.$store.dispatch('auth/getAuthPermissionUrl', {'data': payload}).then(resp => {
					this.processing = false
					window.open(resp.data.url, '_blank')
				}).catch(err => {
					this.processing = false
					window.Bus.$emit('output-error', err)
				})
			}

		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION'
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../../assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-theme-pink {
	background: #FEF1F5;
	border: 0.5px solid $theme-primary-color;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}

.bg-theme-yellow {
	background: #E8B105;
	border: 0.5px solid #E8B105;
}

.text-yellow {
	color: #E8B105;
}

.text-color-deep {
	color: $production-theme !important;
}

.bg-theme-green {
	background: #12CA5B;
	border: 0.5px solid #12CA5B;
}

.text-tertiary {
	color: $theme-tertiary-color !important;
}

.text-secondary {
	color: $theme-secondary-color !important;
}

.text-color-theme {
	color: $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.price-box {
	// text-align: center;
	background-color: #fff;
	border-bottom: 3px solid #e0e0e0;
	box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
	padding: 40px 0 30px;
	margin-bottom: 20px;
	border-radius: 8px 8px;
	transition: all .3s ease-in-out;
	// -webkit-transition: all .3s ease-in-out;
	// -webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}

ul.pricing-list {
	padding: 0 25px;
}

ul, ol {
	list-style: none;
	margin: 0;
	padding: 0;
	// color: #858585;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 20px;
}

ul.pricing-list li.price-title {
	// font-size: 20px;
	// line-height: 24px;
	// font-weight: 700;
	letter-spacing: 1px;
	// color: #071e55;
}

ul.pricing-list li.price-value {
	font-family: dosis, sans-serif;
	// font-size: 70px;
	// line-height: 70px;
	display: block;
	// margin-top: 20px;
	// margin-bottom: 10px;
	// color: #071e55;
}

ul.pricing-list li.price-subtitle {
	font-family: open sans, sans-serif;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 24px;
	// font-weight: 600;
	// color: #6a6a6a;
}

ul.pricing-list li.price-text {
	// display: block;
	font-family: open sans, sans-serif;
	text-align: left;
	font-size: 16px;
	line-height: 28px;
	// font-weight: 400;
	margin-bottom: 5px;
}

.price-box .price-subtitle, .price-box .price-text {
	// color: #6a6a6a;
}

ul.pricing-list > li.price-tag-line a {
	color: #f5c21a;
	background: 0 0;
	border: 1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	// font-weight: 600;
	margin: 30px 0 5px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}

ul.pricing-list li.price-tag a {
	color: #fff;
	background: #f5c21a;
	border: 1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	margin: 30px 0 5px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}

ul.pricing-list li.price-tag-line a:hover, ul.pricing-list li.price-tag a:hover {
	color: #fff;
	background: #f5c21a;
	border: 1px solid #037aef;
}

.ribbon {
	position: absolute;
	right: 8px;
	top: -5px;
	z-index: 1;
	// overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.blue {
	color: #017df7;
}

.ribbon {
	span {
		&:before {
			// content: '';
			position: absolute;
			left: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #79a70a;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #79a70a;
			// border-left-color: #1e5799;
			// border-top-color: #1e5799;
		}
	}
}

.ribbon span::after {
	// content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #79a70a;
	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
	border-right-color: #1e5799;
	border-top-color: #1e5799;
}

.ribbon span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	line-height: 20px;
	transform: rotate(0deg);
	width: 71px;
	// width: 100px;
	display: block;
	// box-shadow: 0 3px 10px -5px #000;
	position: absolute;
	top: 19px;
	right: 7px;
	// right: -21px;
	background-color: #E8B105;
}

.card-side-4 {
	background: $production-theme;
}
</style>
