<template>
	<div>

		<div class="grid grid-cols-1 lg:grid-cols-3 gap-3" v-if="loading">
			<div class=" h-full" v-for="i in 6" :key="i">
				<div
					class="h-full w-full flex flex-col animate-pulse bg-white rounded-lg mb-2 space-y-1">
					<div>
						<div class="h-36 w-full mt-1 mr-10 rounded bg-gray-200"></div>
					</div>
					<div class="flex justify-between px-3">
						<div class="h-4 w-36 mt-2 mr-10 rounded bg-gray-200"></div>
						<!--						<div class="h-5 w-28 mt-2 mr-10 rounded bg-gray-200"></div>-->
					</div>
					<div class="flex justify-between">
						<div class="w-full px-3">
							<div class="h-2 w-full mt-2 ml3 rounded bg-gray-200 "></div>
							<div class="h-2 w-full mt-2 ml3 rounded bg-gray-200"></div>
							<div class="h-2 w-full mt-2 ml3 rounded bg-gray-200"></div>
						</div>

					</div>

				</div>
			</div>
		</div>
		<div class="grid grid-cols-1 lg:grid-cols-3 gap-3" v-else>
			<video-card :video="item" v-for="(item,key) in videoListing" :key="key"/>
		</div>
	</div>
</template>

<script>
import videoCard from "./videoCard";
import 'video.js/dist/video-js.css'

export default {
	name: "videoListing",
	components: {
		videoCard
	},
	props: {
		mode: {
			type: String,
			default: 'portal'
		}
	},
	data: function () {
		return {
			videoListing: [],
			loading: false
		}
	},
	methods: {
		getPortalVideo: function () {
			this.loading = true
			this.$store.dispatch('portal/getVideos').then(resp => {
				let videos = resp.data.results.filter(item => item.file_url !== null)
				videos.map(item => {
					item.options = {
						autoplay: false,
						controls: true,
						responsive: true,
						sources: [
							{
								src: item.file_url,
								type: 'video/mp4'
							}
						]
					}
				})
				this.videoListing = videos
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		getBuyerVideo: function () {
			this.loading = true
			this.$store.dispatch('customer/getVideos').then(resp => {
				let videos = resp.data.results.filter(item => item.file_url !== null)
				videos.map(item => {
					item.options = {
						autoplay: false,
						controls: true,
						responsive: true,
						sources: [
							{
								src: item.file_url,
								type: 'video/mp4'
							}
						]
					}
				})
				this.videoListing = videos
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		initiateVideo: function () {
			if (this.mode === 'portal') {
				this.getPortalVideo()
			} else {
				this.getBuyerVideo()
			}
		}
	},
	mounted() {
		this.initiateVideo()
	}
}
</script>

<style scoped>

</style>
